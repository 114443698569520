import React, { FC } from "react";
import { GlobalStyle, colors } from "../global-style";
import { Center } from "./center";
import { FacebookIcon, LinkedinIcon } from "react-share";
import Helmet from "react-helmet";
import CookieConsent from "react-cookie-consent";

const Layout: FC = props => {
  const { children } = props;

  return (
    <>
      <GlobalStyle />

      <Helmet>
        <title>Werken bij een bakker</title>
      </Helmet>

      {children}

      <CookieConsent
        buttonText="Akkoord"
        buttonStyle={{
          backgroundColor: colors.yellow,
          color: colors.black,
          borderRadius: "0.25rem",
        }}
      >
        Deze website gebruikt cookies voor een optimale gebruikerservaring.
      </CookieConsent>

      <footer style={{ paddingTop: "2rem", paddingBottom: "2rem" }}>
        <Center>
          <div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                margin: "-0.5rem -1rem",
                fontSize: "0.85rem",
              }}
              css={`
                @media (max-width: 65rem) {
                  flex-direction: column;
                }
              `}
            >
              <div
                style={{
                  marginLeft: "1rem",
                  marginRight: "1rem",
                  marginTop: "0.25rem",
                  marginBottom: "0.25rem",
                }}
              >
                Werkenbijeenbakker.nl is onderdeel van{" "}
                <a
                  href="https://www.uitzendbureauhetambacht.nl/"
                  target="_blank"
                  style={{ textDecoration: "underline" }}
                >
                  Het Ambacht
                </a>
              </div>

              <div
                style={{
                  marginLeft: "1rem",
                  marginRight: "1rem",
                  marginTop: "0.25rem",
                  marginBottom: "0.25rem",
                }}
              >
                <a
                  href="https://www.uitzendbureauhetambacht.nl/footer/privacy-statement"
                  target="_blank"
                  style={{ textDecoration: "underline" }}
                >
                  Privacyverklaring
                </a>
              </div>
              <div
                style={{
                  marginLeft: "1rem",
                  marginRight: "1rem",
                  marginTop: "0.25rem",
                  marginBottom: "0.25rem",
                }}
              >
                <a
                  href="https://www.uitzendbureauhetambacht.nl/footer/algemene-voorwaarden"
                  target="_blank"
                  style={{ textDecoration: "underline" }}
                >
                  Algemene voorwaarden
                </a>
              </div>
              <div
                style={{
                  marginLeft: "1rem",
                  marginRight: "1rem",
                  marginTop: "0.25rem",
                  marginBottom: "0.25rem",
                }}
              >
                <a
                  href="mailto:info@werkenbijeenbakker.nl"
                  target="_blank"
                  style={{ textDecoration: "underline" }}
                >
                  info@werkenbijeenbakker.nl
                </a>
              </div>

              <div
                style={{
                  display: "flex",
                  marginLeft: "1rem",
                  marginRight: "1rem",
                  marginTop: "0.25rem",
                  marginBottom: "0.25rem",
                }}
              >
                <a
                  href="https://www.facebook.com/Werken-bij-een-bakker-109843500478051"
                  target="_blank"
                >
                  <FacebookIcon
                    size={35}
                    round={true}
                    bgStyle={{
                      fill: colors.brown,
                    }}
                    iconFillColor={colors.yellow}
                  />
                </a>

                <a
                  href="https://www.instagram.com/werkenbijeenbakker/"
                  target="_blank"
                  style={{
                    position: "relative",
                    top: -2,
                    marginLeft: "0.5rem",
                  }}
                >
                  <svg height={38} viewBox="0 0 67 67" width={38}>
                    <circle r={28} cx={33.5} cy={33.5} fill={colors.yellow} />
                    <path
                      d="M42.271 26.578v-.006c.502 0 1.005.01 1.508-.002.646-.016 1.172-.57 1.172-1.217v-2.89c0-.691-.547-1.24-1.236-1.241h-2.883a1.234 1.234 0 00-1.236 1.243c-.001.955-.004 1.91.003 2.865a1.4 1.4 0 00.073.426c.173.508.639.82 1.209.823l1.39-.001zM33 27.817a6.181 6.181 0 00-.142 12.361c3.454.074 6.248-2.686 6.321-6.043.075-3.46-2.717-6.32-6.179-6.318zm-11.954 3.299v13.627c0 .649.562 1.208 1.212 1.208h21.479c.656 0 1.215-.557 1.215-1.212V31.117H42.04a9.545 9.545 0 01.376 4.014c-.161 1.363-.601 2.631-1.316 3.803s-1.644 2.145-2.779 2.918a9.482 9.482 0 01-9.946.428c-1.579-.885-2.819-2.12-3.685-3.713-1.289-2.373-1.495-4.865-.739-7.451h-2.905zm24.159 18.139c.159-.026.318-.049.475-.083 1.246-.265 2.264-1.304 2.508-2.557.025-.137.045-.273.067-.409V21.794c-.021-.133-.04-.268-.065-.401a3.301 3.301 0 00-2.78-2.618c-.058-.007-.113-.02-.17-.03H20.761c-.147.027-.296.047-.441.08a3.29 3.29 0 00-2.545 2.766c-.008.057-.02.114-.029.171V46.24c.028.154.05.311.085.465a3.302 3.302 0 002.77 2.52c.064.008.13.021.195.03h24.409zM33 64C16.432 64 3 50.568 3 34 3 17.431 16.432 4 33 4s30 13.431 30 30c0 16.568-13.432 30-30 30z"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill={colors.brown}
                    />
                  </svg>
                </a>

                <a
                  href="https://www.linkedin.com/company/werken-bij-een-bakker/about/?viewAsMember=true"
                  target="_blank"
                  style={{ marginLeft: "0.5rem" }}
                >
                  <LinkedinIcon
                    size={35}
                    round={true}
                    bgStyle={{
                      fill: colors.brown,
                    }}
                    iconFillColor={colors.yellow}
                  />
                </a>
              </div>
            </div>
          </div>
        </Center>
      </footer>
    </>
  );
};

export default Layout;
