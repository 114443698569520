import React, { FC, CSSProperties } from "react";
import { centerWidth } from "../global-style";

export const Center: FC<{ hasGutters?: boolean; style?: CSSProperties }> = ({
  hasGutters = true,
  style,
  children,
}) => (
  <div
    style={{
      boxSizing: "content-box",
      maxWidth: centerWidth,
      marginLeft: "auto",
      marginRight: "auto",
      ...(hasGutters && { paddingLeft: "1rem", paddingRight: "1rem" }),
      ...style,
    }}
  >
    {children}
  </div>
);
