import { css, createGlobalStyle } from "styled-components";

export const centerWidth = "65rem";

export const colors = {
  white: "#fff",
  black: "#212121",
  lightGrey: "#dedede",
  iceGrey: "#f4f5f5",
  darkGrey: "#343434",
  brown: "#381300",
  yellow: "#ffb705",
};

export const fontStacks = {
  prequelDemo: `"Prequel Demo", sans-serif`,
  barlow: `"Barlow", sans-serif`,
};

export const fontSizes = {
  1: "0.777rem", // 14px
  2: "0.888rem", // 16px
  3: "1rem", // 18px
  4: "1.111rem", // 20px
  5: "1.444rem", // 26px
  6: "1.777rem", // 32px
  7: "2.222rem", // 40px
  8: "2.5rem", // 45px
  9: "3.333rem", // 60px
};

const globalCSS = css`
  /**
   * Custom fonts
   */

  @font-face {
    font-family: "Prequel Demo";
    src: url("/fonts/prequel-demo.woff2") format("woff2"),
      url("/fonts/prequel-demo.woff") format("woff");
    font-weight: normal;
    font-style: normal;

    /* TODO: is "font-display" needed? */
    /* font-display: swap; */
  }

  /**
   * Use smooth scrolling
   */

  :root {
    scroll-behavior: smooth;
  }

  /**
   * Use a better box model
   */

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  /**
   * Set base text style
   */

  :root {
    font-family: ${fontStacks.barlow};
    font-size: 18px;
    line-height: 1.75;
    color: ${colors.brown};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  /**
   * Set heading style
   */

  h1,
  h2,
  h3 {
    font-family: ${fontStacks.prequelDemo};
    font-weight: normal;
    font-style: regular;
    line-height: 1.25;
  }

  h2 {
    font-size: ${fontSizes[5]};
  }

  @media (max-width: 50rem) {
    h2 {
      font-size: ${fontSizes[4]};
    }
  }

  h3 {
    font-size: ${fontSizes[4]};
  }

  /**
   * Reset paddings and margins
   */

  *,
  h1,
  fieldset {
    margin: 0;
    padding: 0;
  }

  /**
   * Reset list styles
   */

  ul,
  ol,
  dd {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  /**
   * Set image styles
   */

  img {
    display: block;
    max-width: 100%;
  }

  /**
   * Set SVG styles
   */

  svg {
    display: block;
  }

  /**
   * Reset link styles
   */

  a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }

  /**
   * Hide horizontal overflow
   */

  body {
    overflow-x: hidden;
  }
`;

export const GlobalStyle = createGlobalStyle`${globalCSS}`;
